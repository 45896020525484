import child from '@/views/layout/child.vue';
export default [
  {
    name: '奖品管理',
    path: 'goods',
    component: child,
    meta: {
      show: true,
      sort: 10,
    },
    children: [
      {
        path: '/',
        redirect: 'list/inSale',
        meta: {
          show: false,
        },
      },
      {
        name: '',
        path: 'list/:id',
        component: () => import('@/views/goods/list/index.vue'),
        meta: {
          show: true,
        },
      },
      {
        name: '详情',
        path: 'list/:id/detail',
        component: () => import('@/views/goods/list/detail.vue'),
        meta: {
          show: false,
          breadcrumb: true,
        },
      },
      {
        name: '出售中',
        path: 'list/inSale',
        component: () => import('@/views/goods/list/index.vue'),
        meta: {
          show: true,
        },
      },
      // {
      //   name: '已售罄',
      //   path: 'list/soldOut',
      //   component: () => import('@/views/goods/list/index.vue'),
      //   meta: {
      //     show: true,
      //   },
      // },
      {
        name: '仓库中',
        path: 'list/inStock',
        component: () => import('@/views/goods/list/index.vue'),
        meta: {
          show: true,
        },
      },
      {
        name: '回收站',
        path: 'list/inRecycle',
        component: () => import('@/views/goods/list/index.vue'),
        meta: {
          show: true,
        },
      },
      {
        name: '添加商品',
        path: 'add',
        component: () => import('@/views/goods/add/index.vue'),
        meta: {
          show: false,
          breadcrumb: true,
        },
      },

    ]
  }
];
