<template>
	<div class="show-img">
		<div class="demo-upload-list" v-for="(item, index) in uploadList" :key="index">
			<template v-if="item.status === 'finished'">
				<pdf v-if="item.url.indexOf('.pdf') != -1" ref="pdf" :src="item.url" />
				<img v-else :src="item.url">
				<div class="demo-upload-list-cover">
					<Icon type="ios-eye-outline" @click.native="handleView(item.url)"></Icon>
					<Icon type="ios-trash-outline" @click.native="handleRemove(item)"></Icon>
				</div>
			</template>
			<template v-else>
				<Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
			</template>
		</div>
		<Upload v-show="uploadList.length < limit" :show-upload-list="false" ref="upload"
			:data="{ access_token: $store.state.token }" :default-file-list="defaultFileList" :on-success="handleSuccess"
			:accept="type" :max-size="maxSize" :action="action"
			:on-exceeded-size="() => this.$Message.error(`文件大小不能超过${maxSize / 1024}MB`)" style="display: inline-block;">
			<div class="text-icon" :style="{ width: width + 'px', height: height + 'px' }">
				<span class="iconfont icon-tianjiatupian icon"></span>
				<span style="padding: 0 10px;">{{ desc }}</span>
			</div>
		</Upload>

		<Modal title="查看图片" v-model="visible">
			<pdf v-if="imgName.indexOf('.pdf') != -1 && visible" ref="pdf" :src="imgName" style="width: 100%" />
			<img v-if="imgName.indexOf('.pdf') == -1 && visible" :src="imgName" style="width: 100%">
		</Modal>
	</div>
</template>

<script>
import pdf from 'vue-pdf';
export default {
	name: "kdxImageVideo",
	components: { pdf },
	props: {
		// 图片添加下面的名字
		desc: {
			type: String,
			default: '添加图片',
		},
		// 图片限制展示的数量
		limit: {
			type: Number,
			default: 1
		},
		// 多个图片地址
		defaultList: {
			type: Array,
			default() {
				return [];
			}
		},

		type: {
			type: String,
			default: 'image/*',
		},

		// 图片的宽度
		width: {
			type: Number,
			default: 100
		},
		// 图片的高度
		height: {
			type: Number,
			default: 100
		},
		// 只读模式
		isReadonly: {
			type: Boolean,
			default: false
		},
		maxSize: {
			type: Number,
			default: 10 * 1024
		},
		action: {
			type: String,
			default: ""
		},
		msg: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			defaultFileList: [],
			uploadList: [],
			imgName: '',
			visible: false,
		};
	},
	methods: {
		handleView(name) {
			this.imgName = name;
			this.visible = true;
		},
		handleRemove(file) {
			const fileList = this.$refs.upload.fileList;
			this.$refs.upload.fileList.splice(fileList.indexOf(file), 1);

			this.$emit('update:defaultList', this.uploadList);
			this.$emit('change', this.uploadList);
		},
		handleSuccess(response, file, fileList) {
			const { class: className, id, info, path } = response;
			if (className == 'success') {
				this.msg && this.$Message.success(info);
				file.url = path;
				file.id = id;
			} else {
				this.$Message.error(info);
			}

			this.$emit('update:defaultList', this.uploadList);
			this.$emit('change', this.uploadList);

		},
	},
	created() {
		this.defaultFileList = this.defaultList;
	},
	mounted() {

		this.uploadList = this.$refs.upload.fileList;
	}
};
</script>

<style scoped lang="scss">
.text-icon {
	border: 1px dashed $border-color;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border-radius: 2px;

	.icon {
		color: $brand-color;
		font-size: 28px;
		display: block;
		line-height: inherit;
		padding-bottom: 10px;
	}

	span {
		color: $text-zhushi;
		font-size: 12px;
		line-height: 16px;
		display: inline-block;
	}
}

.show-img {
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	.demo-upload-list {
		display: inline-block;
		width: 100px;
		height: 100px;
		text-align: center;
		line-height: 100px;
		border: 1px solid transparent;
		border-radius: 4px;
		overflow: hidden;
		background: #fff;
		position: relative;
		box-shadow: 0 1px 1px rgba(0, 0, 0, .2);
		margin-right: 4px;
	}

	.demo-upload-list img {
		width: 100%;
		height: 100%;
	}

	.demo-upload-list-cover {
		display: none;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba(0, 0, 0, .6);
	}

	.demo-upload-list:hover .demo-upload-list-cover {
		display: block;
	}

	.demo-upload-list-cover i {
		color: #fff;
		font-size: 20px;
		cursor: pointer;
		margin: 0 2px;
	}
}
</style>


