import { get, post, upload } from "./http";

export function addfile(data, custom) {
  return upload("shopapi/upload/addfile", data, custom);
}

export function heartbeat(data, custom) {
  return post("shopapi/index/heartbeat", data, custom);
}



