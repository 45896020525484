/**
 * 防反跳。fn函数在最后一次调用时刻的delay毫秒之后执行！
 * @param fn 执行函数
 * @param delay 时间间隔
 * @param isImmediate 为true，debounce会在delay时间间隔的开始时立即调用这个函数
 * @returns {Function}
 */
export function debounce(fn, delay, isImmediate = false) {

  var timer = null; //初始化timer，作为计时清除依据
  return function () {
    var context = this; //获取函数所在作用域this
    var args = arguments; //取得传入参数
    clearTimeout(timer);
    if (isImmediate && timer === null) {
      //时间间隔外立即执行
      fn.apply(context, args);
      timer = 0;
      return;
    }
    timer = setTimeout(function () {
      fn.apply(context, args);
      timer = null;
    }, delay);
  };
}
/**
 * 创建并返回一个像节流阀一样的函数，当重复调用函数的时候，最多每隔delay毫秒调用一次该函数
 * @param fn 执行函数
 * @param delay 时间间隔
 * @returns {Function}
 */
export function throttle(fn, delay) {
  var timer = null;
  var timeStamp = new Date();
  return function () {
    var context = this; //获取函数所在作用域this
    var args = arguments; //取得传入参数
    if (new Date() - timeStamp > delay) {
      timeStamp = new Date();
      timer = setTimeout(function () {
        fn.apply(context, args);
      }, delay);
    }

  };
}

//时间格式化
export function dateFormat(fmt = 'YY-mm-dd HH:MM:SS', date) {
  if (!date) {
    date = new Date();
  }
  let ret;
  const opt = {
    "Y+": date.getFullYear().toString(), // 年
    "m+": (date.getMonth() + 1).toString(), // 月
    "d+": date.getDate().toString(), // 日
    "H+": date.getHours().toString(), // 时
    "M+": date.getMinutes().toString(), // 分
    "S+": date.getSeconds().toString() // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (let k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")));
    }
  }
  return fmt;
}