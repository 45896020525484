import { replaceImage } from '@/assets/helpers';
import { mapGetters } from 'vuex';
export default {
  computed: {
    // ...mapGetters('config', {
    //   $getPermMap: 'getPermMap',
    // }),
  },
  methods: {
    replaceImage,
    $finishing() {
      this.$Message['error']({
        background: true,
        content: '未完代续'
      });
    }
  }
};