<template>
    <div>
        <Input class="form-input" type="password" password :value="value" @input="handleInput" maxlength="20" size="large"
            :placeholder="placeholder" @on-focus="onFocus" @on-blur="onBlur" @on-enter="enterHandler">
        <!-- <Icon slot="append" type="ios-lock-outline" /> -->
        </Input>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: String
        },
        placeholder: {
            type: String,
            default: '请输入您的登录密码'
        }
    },
    data() {
        return {
            show: false
        };
    },
    methods: {
        handleInput(e) {
            this.$emit('input', e);
        },
        onFocus() {
            this.$emit('on-focus');
        },
        onBlur() {
            this.$emit('on-blur');
        },
        enterHandler() {
            this.$emit('on-enter');
        }
    }
};
</script>
<style lang="scss" scoped></style>