import Vue from 'vue';
import Store from '@/store';

let errorBox = {}; //滚动容器组件；父组件
let throttle = {}; //节流
let errorItems = {}; //缓存错误元素；子组件 

Vue.directive('error-box', {
  // 当被绑定的元素插入到 DOM 中时……
  componentUpdated: function (el, binding) {
    // 聚焦元素
    errorBox[binding.value] = el;
    errorItems[binding.value] = errorItems[binding.value] || {};
  }
});
Vue.directive('error-item', {
  // 当被绑定的元素插入到 DOM 中时……
  componentUpdated: function (el, binding) {
    let keys = Object.keys(binding.modifiers);
    let box = binding.arg || 'kdxContentBar';
    if (keys.length > 0) {
      keys.forEach(item => {
        if (errorItems[box]) {
          errorItems[box][item] = el;
        } else {
          errorItems[box] = { [item]: el };
        }
      });
    }
    if (binding.value) {
      errorItems[box][binding.value] = el;
    }
  }
});