import { model, ext_field, initModel } from './goodAddEdit/jsTools/goodModelData';
import app from '@/main.js';
export default {
  namespaced: true,
  state: {
    model: Object.assign({}, JSON.parse(JSON.stringify(model)), { ext_field }),
    rules: {
      type: [
        {
          required: true,
          message: '商品类型必填',
        },
      ],
      title: [
        {
          required: true,
          message: '商品标题必填',
        },
      ],
      short_name: [
        {
          required: true,
          message: '商品描述必填',
        },
      ],
      content: [
        {
          required: true,
          message: '商品详情必填',
        },
      ],
      thumb: [
        {
          required: true,
          message: '商品主图必填',
        },
      ],
      category_id: [
        {
          required: true,
          message: '商品分类必填',
        },
      ],
      brand: [
        {
          required: true,
          message: '商品品牌必填',
        },
      ],
      sort_by: [
        {
          pattern: /^(0|[1-9][0-9]{0,4})$/,
          message: '商品排序为数字类型，最长为4位数',
        },
      ],
      sales: [
        {
          pattern: /^(0|[1-9][0-9]*)$/,
          message: '虚拟销量为正整数，最长为8位数',
        },
      ],
      goods: [
        {
          required: true,
          message: '商品必填',
          validator(rule, value, callback) {
            if (value && value.length > 0) {
              callback();
            } else {
              callback(new Error('商品必填'));
            }
          },
          type: "array",
        },
      ],
      logistics_support: [
        {
          required: true,
          message: '物流方式必选',
          validator(rule, value, callback) {
            if (value && value.length > 0) {
              callback();
            } else {
              callback(new Error('物流方式必选'));
            }
          },
          trigger: 'blur',
          type: "array",
        },
      ],
      dispatch_type: [
        {
          required: true,
          message: '运费设置必选',
        },
      ],
      dispatch_id: [
        {
          required: true,
          message: '请选择运费模板',
        }
      ],
      status: [
        {
          required: true,
          message: '上架设置必选',
        },
      ],
      reduction_type: [
        {
          required: true,
          message: '库存设置必填',
        },
      ],
      price: [
        {
          required: true,
          message: '原价必填',
        },
        {
          pattern: /^(0|[1-9][0-9]{0,6})(.[0-9]{1,2})?$/,
          message: '支持7位整数两位小数点',
        },
      ],
      original_price: [
        {
          pattern: /^(0|[1-9][0-9]{0,6})(.[0-9]{1,2})?$/,
          message: '支持7位整数两位小数点',
        },
      ],
      cost_price: [
        {
          pattern: /^(0|[1-9][0-9]{0,6})(.[0-9]{1,2})?$/,
          message: '支持7位整数两位小数点',
        },
      ],
      stock: [
        {
          required: true,
          message: '商品库存必填',
        },
        // {
        //     pattern: /^(0|[1-9][0-9]{0,8})$/,
        //     message: '库存为正整数，最长为9位数',
        // },
      ],
      virtual_account_id: [
        {
          required: true,
          message: '卡密库必选',
        },
      ],
      dispatch_price: [
        // {
        //     pattern: /^(0|[1-9][0-9]*)(.[0-9]{1,2})?$/,
        //     message: '支持整数或两位小数',
        // },
      ],
      auto_deliver: [
        {
          required: true,
          message: '自动发货必填',
        },
      ],
      auto_deliver_content: [
        {
          required: true,
          message: '自动发货内容必填',
        },
      ],
      putaway_time: [
        {
          required: true,
          message: '上架时间必填',
        },
      ],
    },

  },
  mutations: {
    setModelProps(state, obj) {
      state.model = {
        ...state.model,
        ...obj,
      };
    },
    resetModel(state) {
      state.model = Object.assign({}, initModel, { ext_field });
      state.model.thumb_list = [];
      state.model.goods = [];
      state.model.thumb_all = [];
      state.model.category_id = [];
      state.model.brand = [];
    },
  },
  actions: {
    // 获得商品信息
    async getGoodData({ commit, state }, goodsData) {
      const { id, operate, stype } = goodsData;
      const { info: result } = await app.$api.goods.goodsdetails({ sid: id }, { stype });
      commit('setModelProps', {
        ...result,
        thumb: result.cover,
        price: result.money,
        cost_price: result.discou,
        type: stype == 'multi' ? '3' : result.type + '',
        stock: result.inventory,
        real_sales: result.buyNum,
        created_at: app.$dateFormat('YY-mm-dd HH:MM:SS', new Date(result.date * 1000)),
        has_option: '0',
        // status: this.status == 4 ? 4 : result.isRelease,
        stype,
        content: result.content,
        thumb_list: [{ url: result.pic, id: result.cover }],
        goods: stype == 'multi' ? JSON.parse(result.shopJson) : [],
        brand: result.brandIds ? result.brandIds.split(',') : [],
        category_id: result.categoryIds ? result.categoryIds.split(',') : []
      });
    },
  },
};