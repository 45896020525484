<template>
    <div class="svg-icon">
        <svg class="icon" aria-hidden="true">
            <use :href="`#${type}`"></use>
        </svg>
    </div>
</template>

<script>
    export default {
        name: "kdxSvgIcon",
        props: {
            // icon名称
            type: {
                type: String,
                required: true
            }
        }
    };
</script>

<style scoped lang="scss">
    .svg-icon {
        display: inline-block;
        font-size: 20px;

        .icon {
            width: 1em;
            height: 1em;
            vertical-align: -0.15em;
            fill: currentColor;
            overflow: hidden;
        }
    }
</style>
