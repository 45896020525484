<template>
  <div>
    <div class="nav-bar">
      <div class="left">
        <div class="logo-box">
          <div class="logo">
            <img :src="defaultLogo" alt="">
          </div>
        </div>
        <div class="left-content">

        </div>
      </div>
      <div class="right">
        <Dropdown transfer @on-click="handleDropdown">
          <div class="userBox">
            <div class="user">
              {{ user.name }}
              <Icon type="ios-arrow-down" class="icon"></Icon>
            </div>
          </div>

          <DropdownMenu slot="list" class="nav-bar-drown-box">
            <template>
              <DropdownItem class="default" name="alone_logout">
                <!-- <span class="iconfont icon-power"></span> -->
                <span class="name">退出登录</span>
              </DropdownItem>
            </template>
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
    <div class="starCrumbs" v-if="$route.meta.breadcrumb">
      <template>
        <div class="modal-name">
          <div class="modal-name-back" @click="goBack" v-if="!$route.meta.show">
            <Icon type="md-arrow-back" />
            <span>返回</span>
          </div>

        </div>
        <Breadcrumb class="breadcrumb">
          <BreadcrumbItem :to="item.path" v-for="(item, index) in breadcrumb" :key="index">{{ item.name }}
          </BreadcrumbItem>
        </Breadcrumb>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'NavBar',
  props: {
    left: {
      type: Number,
    },
  },
  data() {
    return {
      activeName: '首页',
      count: 0,
      breadcrumbStyle: {},
      defaultLogo: 'https://www.zhangzhengyun.com/user/static/img/loginlogo.decbdd2.png'
    };
  },
  computed: {
    ...mapState('alone', ['user']),
    breadcrumb() {
      return this.$route.matched.filter(e => e.path != '/main');
    }
  },
  methods: {
    ...mapActions(["unlogin"]),
    handleDropdown(name) {
      switch (name) {
        case 'alone_logout':
          this.unlogin();
          break;
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="scss">
.nav-bar {
  /*position: fixed;*/
  /*left: 150px;*/
  /*top: 0;*/
  /*right: 0;*/
  height: 64px;
  background: var(--theme-navBackground);
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 0;
  transition: all 0.2s ease-in-out;
  z-index: 1000;
  min-width: 1000px;
  border-bottom: 1px solid $border-color;

  .left {
    display: flex;
    align-items: center;

    .logo-box {
      width: 200px;

      .logo {
        padding: 0 10px;
        height: 50px;
        box-sizing: border-box;
        position: relative;
        text-align: center;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        >img {
          max-width: 100%;
          max-height: 100%;
          //width: 200px;
          //height: 100px;
        }

        h1 {
          font-size: 22px;
          margin-left: 10px;
          color: #FFFFFF;
        }
      }
    }

  }

  .right {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    color: #333;

    .userBox {
      display: flex;
      align-items: center;

    }


    // 用户
    /deep/ .ivu-dropdown {
      &.padMar {
        padding-left: 20px;
        margin-left: 20px;
      }

      .user {
        cursor: pointer;

        .icon {
          font-size: 18px;
        }

        &:hover {

          //color: $brand-color;
          .icon {
            transform: rotate(180deg); // transform: translateY(-50%);
            /*animation: turn 1s linear infinite;*/
          }
        }

        .icon {
          padding-left: 5px;
          padding-right: 5px;
          transition: all 0.6s ease-in-out;
        }
      }
    }

    >.get-out {
      padding: 0 15px;
      font-size: 14px;
      color: $text-first;
      cursor: pointer;
    }

    >.ivu-dropdown {
      .user {
        padding-left: 10px;

        .user-img {
          width: 26px;
          height: 26px;
          margin-right: 12px;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.starCrumbs {
  height: 46px;
  background: #FFFFFF;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  &>.modal-name {
    transition: all 0.2s ease-in-out;
    margin-left: 200px;
    box-sizing: border-box;
    width: 150px;
    height: 46px;
    line-height: 46px;
    text-align: center;
    border-right: 1px solid #e9edef;
    color: #000;

    .modal-name-back {
      cursor: pointer;
    }
  }

  .breadcrumb {
    margin-left: 20px;

    &>span a:hover {
      color: var(--theme-color)
    }

    &>span:last-child {
      a {
        font-weight: normal;
        color: var(--theme-color)
      }

    }
  }

  >.welcome-index {
    display: flex;
    align-items: center;
    padding-left: 20px;
    @include font-18-25-bold;

    /deep/ .date-name {
      color: $warning-color;
    }
  }


}
</style>
