import { get, post } from "./http";

export function finlist(data, custom) {
  return post("shopapi/Finance/finlist", data, custom);
}

export function witlist(data, custom) {
  return post("shopapi/Finance/witlist", data, custom);
}

export function witadd(data, custom) {
  return post("shopapi/Finance/witadd", data, custom);
}

export function witShopIds(data, custom) {
  return post("shopapi/Finance/witShopIds", data, custom);
}

export function witHd(data, custom) {
  return post("shopapi/Finance/witHd", data, custom);
}

export function witFp(data, custom) {
  return post("shopapi/Finance/witFp", data, custom);
}