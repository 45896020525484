export default {
  namespaced: true,
  state: {
    routerEntering: false,
  },
  mutations: {
    setRouterEntering(state, val) {
      state.routerEntering = val;
    },

  },
};