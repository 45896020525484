import child from '@/views/layout/child.vue';
export default [
  {
    name: '订单管理',
    path: 'order',
    component: child,
    meta: {
      show: true,
      sort: 20,
    },
    children: [
      {
        path: '/',
        redirect: 'list/send',
        meta: {
          show: false,
        },
      },
      {
        name: '订单列表',
        path: 'all',
        component: () => import('@/views/order/all/index.vue'),
        meta: {
          show: true,
        },
      },
      {
        name: '',
        path: 'list/:id',
        component: () => import('@/views/order/list/index.vue'),
        meta: {
          show: true,
        },
      },
      {
        name: '未发货订单',
        path: 'list/send',
        component: () => import('@/views/order/list/index.vue'),
        meta: {
          show: true,
        },
      },
      {
        name: '已发货订单',
        path: 'list/payment',
        component: () => import('@/views/order/list/index.vue'),
        meta: {
          show: true,
        },
      },
      {
        name: '已完成订单',
        path: 'list/success',
        component: () => import('@/views/order/list/index.vue'),
        meta: {
          show: true,
        },
      },
      // {
      //   name: '调换货订单',
      //   path: 'list/exchange',
      //   component: () => import('@/views/order/list/index.vue'),
      //   meta: {
      //     show: true,
      //   },
      // },
      {
        name: '详情',
        path: 'detail/:id',
        component: () => import('@/views/order/detail/index.vue'),
        meta: {
          show: false,
          breadcrumb: true,
        },
      },
    ]
  }
];
