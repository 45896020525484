
import { get, post } from "./http";

// 奖品列表
export function orderlist(data, custom) {
  return post("shopapi/Finance/orderlist", data, custom);
}

export function modfiyNumber(data, custom) {
  return post("shopapi/shop/modfiyNumber", data, custom);
}
