import app from '@/main.js';
export default {
  namespaced: true,
  state: {
    user: {},
    isLogin: false
  },
  mutations: {
    setUserInfo(state, user) {
      state.user = {
        ...state.user, ...user
      };
    },
    setLoginStatus(state, isLogin) {
      state.isLogin = isLogin;
    }
  },
  actions: {
    async getUserInfo({ commit, dispatch, state }) {
      const result = await app.$api.user.infos();
      commit("setUserInfo", result);

    },
  },
  getters: {

  }

};
