import { get, post } from "./http";

export function goodslist(data, custom) {
  const stype = custom?.stype;
  if (stype == 'multi') {
    return post("shopapi/shop/packagelist", data, custom);
  }
  return post("shopapi/shop/goodslist", data, custom);
}

export function recyclelist(data, custom) {
  return post("shopapi/shop/recyclelist", data, custom);
}

export function recycleset(data, custom) {
  return post("shopapi/shop/recycleset", data, custom);
}

export function goodsadd(data, custom) {
  return post("shopapi/shop/goodsadd", data, custom);
}

export function packageadd(data, custom) {
  return post("shopapi/shop/packageadd", data, custom);
}

export function goodsdetails(data, custom) {
  const stype = custom?.stype;
  if (stype == 'multi') {
    return post("shopapi/shop/packagedetails", data, custom);
  }
  return post("shopapi/shop/goodsdetails", data, custom);
}

export function inventoryset(data, custom) {
  return post("shopapi/shop/inventoryset", data, custom);
}
// 上下架设置
export function releaseset(data, custom) {
  return post("shopapi/shop/releaseset", data, custom);
}

// 商品删除
export function goodsdel(data, custom) {
  const stype = custom?.stype;
  if (stype == 'multi') {
    return post("shopapi/shop/packagedel", data, custom);
  }
  return post("shopapi/shop/goodsdel", data, custom);
}

// 商品修改
export function goodsedit(data, custom) {
  return post("shopapi/shop/goodsedit", data, custom);
}

// 套餐修改
export function packageedit(data, custom) {
  return post("shopapi/shop/packageedit", data, custom);
}

// 物流公司列表
export function logistics(data, custom) {
  return post("shopapi/shop/logistics", data, custom);
}

// 奖品发货
export function prizesend(data, custom) {
  return post("shopapi/shop/prizesend", data, custom);
}

// 奖品详情
export function prizedetails(data, custom) {
  return post("shopapi/shop/prizedetails", data, custom);
}

// 奖品列表
export function prizelist(data, custom) {
  return post("shopapi/shop/prizelist", data, custom);
}

// 待发货订单导出
export function getGift(data, custom) {
  return get("shopapi/shop/getGift", data, custom);
}

// 奖品结算接口
export function prizeMoneyMember(data, custom) {
  return post("shopapi/shop/prizeMoneyMember", data, custom);
}

// 品类列表
export function categorylist(data, custom) {
  return post("shopapi/extend/categorylist", data, custom);
}

// 品类添加
export function categoryadd(data, custom) {
  return post("shopapi/extend/categoryadd", data, custom);
}

// 品类删除
export function categorydel(data, custom) {
  return post("shopapi/extend/categorydel", data, custom);
}

// 品牌列表
export function brandlist(data, custom) {
  return post("shopapi/extend/brandlist", data, custom);
}

// 品牌添加
export function brandadd(data, custom) {
  return post("shopapi/extend/brandadd", data, custom);
}

// 品牌删除
export function branddel(data, custom) {
  return post("shopapi/extend/branddel", data, custom);
}











