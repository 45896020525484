// 必填规则
export const requiredRule = (message, trigger = 'blur') => [{ required: true, message: message || '必填', trigger }];

/* 手机号校验规则 */
export const mobileRule = [{
    required: true, message: '请输入11位手机号码'
}, {
    pattern: /^1[1-9][0-9]{9}$/,
    message: '请填写正确格式的手机号',
    trigger: 'blur'
}];

/* 图形验证码校验规则 */
export const captchaRule = [{ required: true, len: 4, message: '请输入正确的图形验证码', trigger: 'blur' }];

/* 验证码校验规则 */
export const codeRule = [{
    required: true,
    message: '请输入验证码',
}, {
    pattern: /^\d{4}$/,
    message: '请输入正确的短信验证码',
    trigger: 'blur'
}];


/**
 * 密码等级校验
 * @param str
 * @returns level{1,2,3}
 */
export function levelPass(str) {
    let level = 0;
    //当用户输入的字符串符合一定规则 让等级+1
    if (/\d+/.test(str)) {
        level++;
    }
    if (/[a-zA-Z]+/.test(str)) {
        level++;
    }
    let level3_reg = /[_#@$^%*&!~+-]+/;
    if (level3_reg.test(str)) {
        level++;
    }
    return level;
}