<template>
  <div class="layout">
    <nav-bar></nav-bar>
    <div class="layout-content">
      <sub-menu></sub-menu>
      <div class="content" :class="{ calcHeight: $route.meta.breadcrumb }">
        <div class="router-view" ref="router_view">
          <router-view :containerScrollTop='scrollTop'></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from './components/StarNavBar.vue';
import SubMenu from './components/StarSubMenu.vue';
export default {
  components: {
    NavBar, SubMenu
  },
  provide() {
    return {
      returnToTop: this.returnToTop
    };
  },
  data() {
    return {};
  },
  methods: {
    scrollTop(callback) {
      if (typeof callback == 'function') {
        this.childCallback = callback;
      }
    },
    returnToTop(top = 0) {
      this.$refs['router_view']?.scrollTop && (this.$refs['router_view'].scrollTop = top);
    },
  }
};
</script>

<style lang="scss" scoped>
.layout {
  /*display: flex;*/
  /*flex-wrap: nowrap;*/
  min-width: 1336px;
  overflow-x: auto;
  overflow-y: hidden;

  .layout-content {
    display: flex;

    >.content {
      // margin-top: 56px;
      width: 100%;
      height: calc(100vh - 64px);
      overflow-y: auto;
      overflow-x: hidden;
      position: relative;

      /*background-color: #fff;*/
      &.calcHeight {
        height: calc(100vh - 110px);
      }

      .router-view {
        padding: 0 20px 0 20px;
        height: 100%;
        background-color: $background-color;
        /*box-sizing:border-box;*/
        overflow-y: auto;
        overflow-x: hidden;
        transition: all 10s ease-in-out;
        position: relative;


        /deep/>div {
          position: relative;
          min-height: 100%;
        }
      }
    }
  }
}
</style>