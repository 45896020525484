import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);
import $store from '@/store';
import Layout from '@/views/layout/index.vue';
import login from '@/views/login/index.vue';
import ViewUI from 'view-design';
// 解决路由报错 NavigationDuplicated
const routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};
const originalReplace = Router.prototype.replace;
Router.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err);
};

let paths = require.context('./', false, /\/.*\.js$/); //获取当前全部路由
let children = [];
paths
  .keys()
  .map((item) => {
    //获取本级目录下的全部配置参数
    return paths(item).default;
  })
  .filter((item) => item)
  .sort((a, b) => a[0].meta.sort - b[0].meta.sort)
  .forEach((item) => {
    children = children.concat(item);
  });

const routes = [
  {
    name: '',
    path: '/',
    redirect: '/main',
  },
  {
    name: '登录',
    path: '/login',
    component: login,
  },
  {
    name: '菜单',
    path: '/main',
    component: Layout,
    meta: {
    },
    children: [
      {
        path: '/',
        redirect: 'home',
        meta: {
          show: false,
        },
      },
      ...children
    ]
  },
];

console.log(routes);
const router = new Router({ routes });


const whiteList = ['/login']; //不验证是否登录的路由白名单

router.beforeEach(async (to, from, next) => {
  ViewUI.LoadingBar.start();
  const { isLogin } = $store.state.alone;
  const isWhite = whiteList.includes(to.path);
  if (!isLogin && !isWhite) {
    next({ path: '/login' });
    return;
  }
  next();
});


router.afterEach(async (to, from, next) => {
  ViewUI.LoadingBar.finish();
});


export default router;