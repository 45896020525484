<template>
    <div :class="[`page-component-size-${size}`]">
        <Page :total="total" :page-size="page.pageSize" :show-total="showTotal" :show-elevator="showElevator"
            :current.sync="page.pageNumber" :page-size-opts="pageSizeOption" :show-sizer="showSizer" transfer
            @on-change="pageNumberChange" @on-page-size-change="pageSizeChange" />
    </div>
</template>

<script>
export default {
    name: "kdxPageComponent",
    props: {
        total: {
            type: Number,
            default: 0
        },
        // 每页多少条
        pageSize: {
            type: Number,
            default: 10
        },
        // 当前页码
        current: {
            type: Number,
            default: 1
        },
        pageSizeOption: {
            type: Array,
            default: () => [10, 20, 100]
        },
        showTotal: {
            type: Boolean,
            default: true
        },
        // 显示快速切换页面
        showElevator: {
            type: Boolean,
            default: true
        },
        // 显示分页
        showSizer: {
            type: Boolean,
            default: true
        },
        limitDefault: {
            type: Boolean,
            default: false
        },
        size: { // 页码small模式
            type: String,
            default: ''
        }
    },
    watch: {
        current: {
            immediate: true,
            handler() {
                this.page = {
                    ...this.page,
                    pageNumber: this.current
                };
            }
        },
        pageSize: {
            immediate: true,
            handler() {
                this.page = {
                    ...this.page,
                    pageSize: this.pageSize
                };
            }
        },
    },
    data() {
        return {
            page: {
                pageSize: this.pageSize,
                pageNumber: this.current
            }
        };
    },
    methods: {
        pageNumberChange(pageNumber) {
            this.page.pageNumber = pageNumber;
            this.$emit('on-change', this.page);
        },
        pageSizeChange(pageSize) {
            this.page.pageSize = pageSize;
            this.$emit('on-change', this.page);
            this.$emit('on-page-size-change', this.page);
        },
        reset(page = { pageSize: 10, pageNumber: 1 }) {
            Object.assign(this.page, page);
            // this.$emit('on-change', this.page);
        }
    }
};
</script>

<style scoped></style>
