<template>
    <div class='lazy-load-image'>
        <img class='img preload-img' :src="getLoadingSrc" :mode="mode" :fade-show='fadeShow' />
        <img class='img succ-img' :style='{ opacity: loading ? 0 : 1, position: loading ? "absolute" : "relative" }'
            :src="getSrc" :mode="mode" :lazy-load="lazyLoad" :fade-show='fadeShow' :webp='webp' @error='error'
            @load='load' />
    </div>
</template>

<script>
export default {
    name: 'kdx-lazy-image',
    props: {
        src: {
            type: String,
            default: ''
        },
        preSrc: {
            type: String,
            default: ''
        },
        errSrc: {
            type: String,
            default: ''
        },
        mode: {
            type: String,
            default: 'scaleToFill'
        },
        lazyLoad: {
            type: Boolean,
            default: true
        },
        fadeShow: {
            type: Boolean,
            default: true
        },
        showMenuByLongpress: {
            type: Boolean,
            default: false
        },
        webp: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loading: true,
            failed: false,
            defaultErrorSrc: '',
            defaultLoadingSrc: ''
        };
    },
    computed: {
        getErrSrc() {
            if (this.errSrc) {
                return this.errSrc;
            }
            return '';
        },
        getLoadingSrc() {
            if (this.preSrc) {
                return this.preSrc;
            }
            return '';
        },
        getDefaultSrc() {
            if (this.src) {
                return this.src;
            }
            return '';
        },
        getSrc() {
            let src = '';
            if (this.failed && this.getErrSrc) { //加载失败
                src = this.getErrSrc;
            } else if (this.loading && this.getLoadingSrc) { //正在加载
                src = this.getLoadingSrc;
            } else { //最后展示的图
                src = this.getDefaultSrc;
            }
            return src;
        }
    },
    methods: {
        error(e) {
            this.failed = true;
            this.$emit("error", e);
        },
        load(e) {
            this.loading = false;
            this.$emit("load", e);
        }
    }
};
</script>

<style lang="scss" scoped>
image,
img {
    will-change: transform
}

.lazy-load-image {
    width: 300px;
    height: 225px;
    display: inline-block;
    position: relative;
    overflow: hidden;
    font-size: 0;
    line-height: 0;

    .img {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    .preload-img {
        position: relative;
        z-index: 1;
    }

    .succ-img {
        z-index: 0;
    }
}
</style>