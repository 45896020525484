import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import app from '@/main.js';

let paths = require.context('./', false, /\/.*\.js$/);//获取当前全部路由
let modules = {};//各个模块的集合
let moduleName = [];
paths.keys().map(item => {//获取本级目录下的全部配置参数
  if (item != './index.js') {
    moduleName.push(item.replace(/(\.\/|\.js)/g, ''));
    return paths(item).default;
  }
  return '';
}).filter(item => item).forEach((item, index) => {
  modules[moduleName[index]] = item;
});
Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    ...modules,//文件名即为模块名
  },
  state: {
    token: '',
    temp_token: '',
  },
  mutations: {
    setToken(state, data) {
      state.token = data;
    },
    setTempToken(state, data) {
      state.temp_token = data;
    },
  },
  actions: {
    //登出
    async unlogin({ commit, dispatch }, toast = 1) {
      if (toast === 1) {
        await app.$api.auth.clearShopLogin({}, { toast, loading: true });
      }
      commit('setToken', '');
      commit('setTempToken', '');
      commit('alone/setUserInfo', '');
      commit('alone/setLoginStatus', false);
      app.$router.replace('/login');
    }
  },
  getters: {

  },
  plugins: [
    createPersistedState({
      // storage: window.sessionStorage,
      reducer: state => {
        return {
          // 要保存的数据
          alone: state.alone,
          token: state.token,
          temp_token: state.temp_token,
        };
      }
    })
  ]
});