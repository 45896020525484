<template>
  <div>
    <Input class="form-input" :value="value" @input="handleInput" maxlength="6" size="large" :placeholder="placeholder"
      @on-focus="onFocus" @on-blur="onBlur" @on-enter="enterHandler">
    <template slot="append">
      <Button :disabled="disabled" :loading="loading" @click="sendCode">{{ btnText }}</Button>
    </template>
    </Input>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    btnText: {
      type: String,
      default: '获取验证码'
    },
    placeholder: {
      type: String,
      default: '请输入验证码'
    }
  },
  data() {
    return {
      show: false
    };
  },
  methods: {
    sendCode() {
      this.$emit('on-send-code');
    },
    handleInput(e) {
      this.$emit('input', e);
    },
    onFocus() {
      this.$emit('on-focus');
    },
    onBlur() {
      this.$emit('on-blur');
    },
    enterHandler() {
      this.$emit('on-enter');
    }
  }
};
</script>
<style lang="scss" scoped>
.form-input /deep/ .ivu-btn {
  height: 38px;
}
</style>