<template>
  <div :style="submenuStyle">
    <div class="submenu">
      <Menu ref="submenu" :active-name="activeName" :open-names="openName" @on-select="handleSelect" class="menu_content">
        <template v-for="menu in getSunMenuList">
          <keep-alive :key="'/main/' + menu.path" v-if="menu.meta.show !== false && menu.name">
            <Submenu v-if="menu.children && menu.children.length" :name="'/main/' + menu.path">
              <template #title>
                <div class="submenuTitle">
                  {{ menu.name }}
                </div>
              </template>
              <template v-for="child in menu.children">
                <MenuItem :name="'/main/' + menu.path + '/' + child.path" :key="'/main/' + menu.path + '/' + child.path"
                  v-if="child.meta.show !== false && child.name">
                <div class="submenuTitle">
                  <!-- <span class="iconfont" :class="[child.meta.icon]"></span> -->
                  {{ child.name }}
                </div>
                </MenuItem>
              </template>
            </Submenu>
            <MenuItem v-else :name="'/main/' + menu.path">
            <div class="submenuTitle">
              <!-- <span :class="[menu.meta.icon]"></span> -->
              {{ menu.name }}
            </div>
            </MenuItem>
          </keep-alive>
        </template>
      </Menu>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: null,
      openName: [],
    };
  },
  computed: {
    submenuStyle() {
      return this.$route.meta.breadcrumb ? { marginTop: '-46px' } : {};
    },
    getSunMenuList() {
      return this.$router.options.routes.find(e => e.path == '/main').children || [];
    },
  },
  watch: {
    '$route.path': {
      immediate: true,
      handler(path) {
        console.log(this.$route);
        if (path && this.$route.matched[1]?.path && this.$route.matched[1]?.meta.show != false) {
          const openRoute = this.$route.matched[1].path;
          const isOpen = this.openName.indexOf(openRoute);
          if (isOpen == -1) {
            this.openName.push(openRoute);
          }
          if (this.$route.meta.show != false) {
            this.activeName = path;
          }

        }

      }
    }
  },
  created() {
  },
  methods: {

    handleSelect(name) {
      const path = name;
      if (path != this.$route.path) {
        this.$router.push({ path });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.submenu {
  position: relative;
  /*margin-top: 56px;*/
  box-shadow: 2px 0 4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  height: calc(100vh - 64px);
  /* margin-top: -46px; */
  transition: all 0.2s ease-in-out;
  flex-shrink: 0;

  /deep/ .menu_content {
    width: 100% !important;
    height: 100%;
    overflow-y: auto;
    padding-top: 10px;
    box-shadow: $shadow-card;

    li {
      width: 200px !important;
    }

    .submenuTitle {
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 15px;
      //color: red;

      span {
        font-size: 15px;
        padding-right: 6px;
        font-weight: bold;
        color: #1D2129;
      }

      &:hover {
        color: var(--theme-color);

        i {
          color: var(--theme-color);
        }

        span {
          color: var(--theme-color);
        }
      }
    }

    .ivu-menu-submenu {
      padding: 0;
      width: 100%;
      min-height: 46px;

      .ivu-menu-submenu-title {
        height: 46px;
        line-height: 46px;
        box-sizing: border-box;
        padding: 0 20px;
        color: #1D2129;
        font-weight: bold;

        &:hover {

          i {
            color: var(--theme-color);
          }

        }

        i {
          font-weight: bold;
          color: #1D2129;
        }
      }
    }

    .ivu-menu-submenu.ivu-menu-opened {


      i {
        font-weight: bold;
        color: #1D2129;
        transform: translateY(-50%) rotate(0);
      }
    }

    .ivu-menu-item {
      height: 50px;
      color: #1D2129;

      &:hover {
        color: var(--theme-color);

        span {
          color: var(--theme-color);
        }
      }

      &.ivu-menu-item-active:not(.ivu-menu-submenu) {
        span {
          color: var(--theme-color);
        }

        background-color: var(--theme-submenuBackground);
        font-weight: bold;
      }
    }

    li,
    a {
      padding: 16px 20px;
      font-size: 14px;
      line-height: 17px;

      .ivu-menu-submenu-title {
        padding: 0;
        position: relative;
        font-size: 16px;

        i {
          transition: all 0.2s;
          position: absolute;
          right: 10px;
          margin: 0;
          top: 50%;
          transform: translateY(-50%) rotate(-90deg);
          font-size: 16px;
        }
      }

      .ivu-menu {
        >.ivu-menu-item {
          display: flex;
          align-items: center;
          padding-left: 40px !important;
          line-height: 50px;

          &.ivu-menu-item-active {
            &:not(.ivu-menu-item-submenu) {
              background-color: var(--theme-submenuBackground);
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}
</style>