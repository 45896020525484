import { get, post } from "./http";

export function changeAvatar(data, custom) {
  return post("shopapi/member/changeAvatar", data, custom);
}
// 商家个人信息
export function infos(data, custom) {
  return post("shopapi/member/infos", data, custom);
}

export function mBinding(data, custom) {
  return post("shopapi/member/mBinding", data, custom);
}

export function changeinfo(data, custom) {
  return post("shopapi/member/changeinfo", data, custom);
}

export function changePhone(data, custom) {
  return post("shopapi/member/changePhone", data, custom);
}
//查询是否绑定微信
export function synbindingWx(data, custom) {
  return post("shopapi/member/synbindingWx", data, custom);
}
//开启关闭通知
export function changeNotice(data, custom) {
  return post("shopapi/member/changeNotice", data, custom);
}