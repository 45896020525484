import { get, post } from "./http";

export function login(data, custom) {
  return post("shopapi/login/login", data, custom);
}

export function getSmsCode(data, custom) {
  return post("shopapi/login/getSmsCode", data, custom);
}
//退出登录
export function clearShopLogin(data, custom) {
  return post("shopapi/login/clearShopLogin", data, custom);
}
//Token保活
export function heartbeat(data, custom) {
  return post("shopapi/index/heartbeat", data, custom);
}
// 获取二维码图片data
export function getCodeData(data, custom) {
  return post("shopapi/login/getWxqrcodeToken", data, custom)
}
// 轮询接口获取是否扫码登录成功
export function getLoginStatus(data, custom) {
  return post("shopapi/login/synLogin", data, custom)
}