import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import router from './router';
import store from './store';
import ViewUI from 'view-design';
// import iViewPro from '@/libs/iview-pro/iview-pro.min.js';
import VueClipboard from 'vue-clipboard2';
import 'view-design/dist/styles/iview.css';
import '@/libs/iview-pro/iview-pro.css';
import '@/assets/css/reset.scss';
import '@/assets/css/theme.css';
import '@/assets/font/iconfont.css';
import { media } from '@/assets/helpers'; // 图片文件地址
import Loading from '@/components/loading/index';
import mixins from '@/helpers/mixins.js';
import directives from '@/helpers/directives.js';
import * as utils from '@/assets/helpers';
import './changeTheme.js';
import '@/api';
import CONFIG from '@/config';
import { filters } from './helpers/filters';
import { dateFormat } from '@/helpers/common.js';
import E from "wangeditor";
import '@/api/index.js';
import { heartbeat } from '@/api/other.js';
import axios from 'axios';
Vue.prototype.$axios = axios;
Vue.prototype.$E = E;


Vue.use(VueRouter);
Vue.config.productionTip = false;
Vue.prototype.$media = media;
Vue.prototype.$utils = utils;
Vue.prototype.$css = window.cssThemeVar;
Vue.prototype.$baseUrl = CONFIG.baseUrl;
Vue.prototype.$action = CONFIG.baseUrl + 'shopapi/upload/addfile';
Vue.prototype.$dateFormat = dateFormat;

Object.keys(filters).forEach(key => Vue.filter(key, filters[key]));

Vue.use(ViewUI, {
  transfer: true
});
// Vue.use(iViewPro);
// 复制文本
Vue.use(VueClipboard);
Vue.use(Loading);
//全局自定义组件
let requireComponent = require.context('./components', true, /\/.*\.g\.vue$/);
requireComponent.keys().forEach(fileName => {
  // 获取组件配置
  const componentConfig = requireComponent(fileName);
  const component = componentConfig.default || componentConfig;
  // 组件注册,组件必须写name
  Vue.component(component.name, component);
});
Vue.mixin(mixins);


//解决同一浏览器多个网页登录多个账户问题 
function reAuth(ctx) {
  const local_vuex = localStorage.getItem('vuex');
  if (!local_vuex) return;
  const vuex = JSON.parse(local_vuex);
  const token = vuex.token;
  if (token && document.visibilityState == "visible" && store.state.alone.isLogin) {
    if (token != store.state.token) {
      //最新token和vuex中token不同 取最新（本地） 重新获取用户数据
      window.location.reload();
    } else {
      heartbeat();
    }
  }

}


const app = new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    window.addEventListener("visibilitychange", function () {
      reAuth(this);
    });
  }
}).$mount('#app');

export default app;