<template>
    <div class="form-title" :class="{ hasMargin: hasMargin }">
        <span> {{ content }}</span>
        <slot></slot>
        <span class="text">
            <slot name="text"></slot>
        </span>
    </div>
</template>

<script>
// 用于form表单上面的标题显示--如添加商品上基本信息
export default {
    name: 'kdxFormTitle',
    props: {
        content: {
            type: String
        },
        hasMargin: {
            type: Boolean,
            default: true
        }
    }
};
</script>

<style scoped lang="scss">
.form-title {
    @include font-16-22-bold;
    padding: 9px 20px;
    background-color: $background-color;
    border-radius: 2px;

    &.hasMargin {
        margin-bottom: 30px;
    }

    .text {
        @include font-12-16;
        color: $text-first;
        font-weight: normal;
    }
}
</style>
