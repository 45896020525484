<template>
  <div class="layout">
    <div class="login-form">
      <div class="btn" @click="btnHandler">
        {{ reseve ? "手机号登录" : "微信扫码登录" }}
      </div>
      <div class="login-form_top">
        <img
          class="login-form_logo"
          src="https://www.zhangzhengyun.com/user/static/img/loginlogo.decbdd2.png"
          alt=""
        />
      </div>
      <div class="login-form_bt" v-if="!reseve">
        <div class="login-form_form">
          <Form
            label-position="top"
            :model="model"
            :rules="rules"
            ref="form"
            @submit.native.prevent
          >
            <form-item label="手机号码" prop="username">
              <log-input
                :class="[focusInput === 'Mobile' ? 'input-focus' : '']"
                @on-focus="onFocus('Mobile')"
                @on-blur="onBlur"
                v-model="model.username"
                @on-enter="toLogin"
                placeholder="请输入手机号码"
                style="width: 370px"
                maxlength="11"
              />
            </form-item>
            <form-item
              v-if="loginType == 'password'"
              label="密码"
              prop="password"
              style="margin-bottom: 20px"
            >
              <pass-input
                :class="[focusInput === 'Pass' ? 'input-focus' : '']"
                @on-focus="onFocus('Pass')"
                @on-blur="onBlur"
                v-model="model.password"
                @on-enter="toLogin"
                placeholder="请输入密码"
              />
            </form-item>
            <form-item
              v-if="loginType == 'code'"
              label="验证码"
              prop="code"
              style="margin-bottom: 20px"
            >
              <code-input
                :class="[focusInput === 'code' ? 'input-focus' : '']"
                @on-focus="onFocus('code')"
                @on-blur="onBlur"
                v-model="model.code"
                @on-enter="toLogin"
                placeholder="请输入验证码"
                :btnText="sms.timerTxt"
                :loading="sms.loading"
                :disabled="sms.time > 0"
                @on-send-code="sendCode"
              />
            </form-item>
          </Form>

          <!-- <div class="login-form_pass">
            <Checkbox v-model="rememberStatus" style="transform: scale(0.9)" label="">记住密码
            </Checkbox>
          </div> -->

          <div class="login-form_submit">
            <log-button @click="toLogin" long>登录</log-button>
          </div>
        </div>
      </div>
      <div class="code" v-if="reseve">
        <img :src="codeUrl" alt="" />
      </div>
      <div v-if="reseve" class="codenext" @click="nextHandler"></div>
    </div>
  </div>
</template>
<script>
import LogInput from "./components/LogInput.vue";
import PassInput from "./components/PassInput.vue";
import CodeInput from "./components/CodeInput.vue";
import LogButton from "./components/Button.vue";
import { requiredRule, mobileRule } from "@/helpers/validateRule.js";
import GetSms from "@/helpers/getSms";
import QRCode from "qrcode";
export default {
  components: {
    LogInput,
    PassInput,
    LogButton,
    CodeInput,
  },
  data() {
    return {
      rememberStatus: true,
      focusInput: "",
      loginType: "code",
      model: {
        username: "",
        password: "",
        code: "",
      },
      rules: {
        username: mobileRule,
        password: requiredRule("请输入密码"),
        code: requiredRule("请输入验证码"),
      },
      showCode: false,
      sms: new GetSms({ id: 1, repeatTime: 60 }),
      reseve: false,
      codeUrl: null,
      time: null,
      codeData: null,
    };
  },
  created() {},
  methods: {
    sendCode() {
      this.$refs.form.validateField("username", (err) => {
        if (err) {
          this.$Message.warning(err);
          return;
        }
        this.sms.sendCode(() => {
          return this.$api.auth
            .getSmsCode({ mobile: this.model.username }, { toast: 1 })
            .then((res) => {
              this.$store.commit("setTempToken", res.temp_token);
              return Promise.resolve("success");
            });
        });
      });
    },
    onFocus(focusName) {
      this.focusInput = focusName;
    },
    onBlur() {
      console.log("blur");
      this.focusInput = "";
    },
    toLogin() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitLogin();
        }
      });
    },
    async submitLogin() {
      const { username, code, password } = this.model;
      const { temp_token } = this.$store.state;
      const res = await this.$api.auth.login(
        { mobile: username, smscode: code, temp_token },
        { toast: 1 }
      );
      this.$store.commit("alone/setLoginStatus", true);
      this.$store.commit("alone/setUserInfo", res);
      this.$store.commit("setToken", res.access_token);
      this.$router.push("/");
    },
    async btnHandler() {
      this.reseve = !this.reseve;
      if (!this.reseve) {
        clearInterval(this.time);
      } else {
        this.codeData = await this.$api.auth.getCodeData();
        this.codeUrl = await QRCode.toDataURL(
          `https://zzy2.zhangzhengyun.com/shopapi/login/wxqrcode/qrtoken/${this.codeData}`
        );
        this.time = setInterval(async () => {
          let res = await this.$api.auth.getLoginStatus(
            {
              qrtoken: this.codeData,
            },
            { toast: 2 }
          );
          if (res.access_token) {
            this.$store.commit("alone/setLoginStatus", true);
            this.$store.commit("alone/setUserInfo", res);
            this.$store.commit("setToken", res.access_token);
            this.$router.push("/");
            clearInterval(this.time);
          }
        }, 5000);
      }
    },
    async nextHandler() {
      this.codeData = await this.$api.auth.getCodeData();
      this.codeUrl = await QRCode.toDataURL(
        `https://zzy2.zhangzhengyun.com/shopapi/login/wxqrcode/qrtoken/${this.codeData}`
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.code {
  width: 300px;
  height: 300px;
  position: relative;
  margin: 0 auto;
  margin-top: 100px;
  box-shadow: 3px 5px 20px 0 #dae9fa;
  img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.codenext {
  width: 300px;
  height: 300px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: 210px;
}
.codenext:hover {
  cursor: pointer;
  background: rgba($color: #000000, $alpha: 0.5);
  &::after {
    content: "点点手指换一张";
    color: white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-weight: 800;
    font-size: 18px;
  }
}
.layout {
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.login-form {
  position: relative;
  margin: auto;
  transform: translateY(-15px);
  border-radius: 10px;
  width: 490px;
  height: 630px;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(133, 148, 162, 0.2);
  display: flex;
  align-items: center;
  flex-direction: column;
  .btn {
    width: 100px;
    height: 35px;
    position: absolute;
    right: 10px;
    top: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    text-align: center;
    line-height: 35px;
    font-weight: 500;
  }
  .btn:hover {
    cursor: pointer;
  }
  /deep/ .ivu-form-item-error .ivu-input {
    border: 1px solid #ed4014 !important;
  }

  /deep/ .input-focus {
    position: relative;
    z-index: 10;

    .ivu-input-group-append i {
      color: var(--theme-color);
      font-weight: bold;
    }

    &:before {
      content: "";
      width: 2px;
      height: 100%;
      position: absolute;
      left: -1px;
      top: 0;
      z-index: 10;
      background-color: var(--theme-color);
    }

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 10px;
      z-index: -1;
      background-color: var(--theme-color);
      filter: blur(10px);
      opacity: 1;
      opacity: 0.08;
    }
  }

  &_ {
    &top {
      padding-top: 60px;
      display: flex;
      align-items: center;
      justify-content: center;

      h1 {
        color: var(--theme-color);
        font-size: 44px;
      }
    }

    &logo {
      width: 200px;
    }

    &bt {
      padding-top: 78px;
    }

    &pass {
      /deep/ .ivu-checkbox-wrapper {
        color: #262b30;
        font-size: 14px;
      }
    }

    &submit {
      width: 370px;
      height: 50px;
      position: absolute;
      left: 60px;
      bottom: 83px;
    }
  }
}
</style>
