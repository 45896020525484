import child from '@/views/layout/child.vue';
export default [
  {
    name: '账户和设置',
    path: 'setting',
    component: child,
    meta: {
      show: true,
      sort: 40,
    },
    children: [
      {
        name: '商家信息设置',
        path: 'profile',
        component: () => import('@/views/setting/profile/index.vue'),
        meta: {},
      },
      {
        name: '账户密码设置',
        path: 'account',
        component: () => import('@/views/setting/account/index.vue'),
        meta: {},
      },
    ]
  }
  // {
  //   name: '账户和设置',
  //   path: 'profile',
  //   component: () => import('@/views/setting/account/index.vue'),
  //   meta: {
  //     show: true,
  //     sort: 40,
  //   },
  // },
];
