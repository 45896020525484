
import Vue from 'vue';
import Loading from './index.vue';

const Mask = Vue.extend(Loading);
const loadingDirective = {};

loadingDirective.install = Vue => {

    const toggleLoading = (el, binding) => {
        if (binding.value) {
            el.instance.visible = true;
            el.instance.title = binding.title || '正在加载...';
            insertDom(el, el, binding);
        } else {
            el.instance.visible = false;
        }
    };
    const insertDom = (parent, el) => {
        el.instance.visible = true;
        parent.appendChild(el.mask);
    };

    Vue.directive('loading', {
        bind: function (el, binding) {
            const mask = new Mask({
                el: document.createElement('div'),
                data: {
                    fullscreen: !!binding.modifiers.fullscreen
                }
            });
            el.instance = mask;
            el.mask = mask.$el;
            if (typeof binding.value == 'boolean') {
                binding.value && toggleLoading(el, binding);
            } else {
                binding.title = binding.value.title;
                binding.value = binding.value.loading;
                binding.value && toggleLoading(el, binding);
            }
        },
        update: function (el, binding) {
            if (typeof binding.value !== 'boolean') {
                binding.title = binding.value.title;
                binding.value = binding.value.loading;
            }

            toggleLoading(el, binding);
        },
        unbind: function (el) {
            el.instance && el.instance.$destroy();
        }
    });
};

export default loadingDirective;
