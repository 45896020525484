// 获取验证码
class GetSms {
  // { id, repeatTime, time }
  constructor() {
    const { id = 1, repeatTime = 60, time = 0, timerTxt = '发送验证码' } = { ...arguments[0] };
    this.id = id;
    this.repeatTime = repeatTime;
    this.time = time;
    this.timer = null;
    this.loading = false;
    this.timerTxt = timerTxt;
    this.initTimerTxt = timerTxt;
    //刷新页面重新赋值
    this.initSms();
  }

  initSms() {
    let _now = localStorage.getItem(`sms_${this.id}_now`);
    let _delay = localStorage.getItem(`sms_${this.id}_delay`);
    let secTime = parseInt((new Date().getTime() - _now) / 1000);
    if (secTime < _delay) {
      this.time = _delay - secTime;
      this.initAuthTimer(_delay);
    }
  }

  async sendCode(fn) {
    this.loading = true;
    let res = await fn().catch(e => {
      console.log(e);
      this.loading = false;
    });
    if (!res) return;
    this.loading = false;
    this.time = this.repeatTime;
    this.getTimerTxt();
    this.initAuthTimer();
  }

  initAuthTimer() {
    this.timer = window.setInterval(() => {
      this.time--;
      this.getTimerTxt();
      localStorage.setItem(`sms_${this.id}_delay`, this.time);
      localStorage.setItem(`sms_${this.id}_now`, new Date().getTime());
      if (this.time == 0) window.clearInterval(this.timer);
    }, 1000);
  }

  getTimerTxt() {
    this.timerTxt = this.time > 0
      ? this.time + "s后重新获取"
      : this.initTimerTxt;
  }
}

export default GetSms;