'use strict';

import axios from 'axios';
import CONFIG from '@/config';
import store from '@/store';
import app from '@/main.js';
import { throttle } from '@/helpers/common.js';

const instance = axios.create({
  baseURL: CONFIG.baseUrl,
  timeout: 5000,
  dataType: 'json',
  custom: {
    loading: false,
    toast: 0, // 0 code错误时提示，1 正确错误都提示，2 不提示
  },
  data: {},
  params: {}
});

let count = 0; // 发起请求的次数

instance.interceptors.request.use(config => {
  // Do something before request is sent
  // 设置身份凭证 ......
  console.log(config);
  const { data, params, method } = config;
  let { custom } = config;
  if (custom.loading) {
    count++;
    if (count < 2) {
      //展示加载框
    }
  }

  const type = method === "get" ? "params" : "data";
  config[type].access_token = store.state.token;

  return config;
}, error => {
  console.log('request', error);
  // Do something with request error
  return Promise.reject(error);
});

instance.interceptors.response.use(response => {
  // Do something before response is sent
  // 2xx 范围内的状态码都会触发该函数。
  clearLoading(response);
  const { data, config, status } = response;

  if (status == 200) {

    //特殊处理
    if (config.url == 'shopapi/upload/addfile') {
      if (data.path) {
        if (config.custom.toast == 1) {
          app.$Message.success(data.info || '上传成功');
        }
        return data;
      } else {
        app.$Message.warning(data.info || '上传失败');
      }
    }

    // if 与后台规定的成功码是否正常
    data.status = data.status || 0;
    if (data.code == 0 && data.status == 0) {
      if (config.custom.toast == 1) {
        app.$Message.success(data.msg);
      }
      return data.result || data.data;
    }
    if (data.code == 1001) {
      unlogin(config, data);


    } else {
      if (config.custom.toast != 2) {
        app.$Message.warning(data.msg);
      }

    }
  }
  return Promise.reject(data.result || data.data);
}, error => {
  // Do something with response error
  // 超出 2xx 范围的状态码都会触发该函数。
  console.log(error);

  clearLoading(error);

  try {
    const { status, statusText } = error.response;
    app.$Message.error(statusText || '服务端发生错误');
  } catch (error) {
    app.$Message.error('未知错误');
  }

  return Promise.reject(error);
});
const unlogin = throttle(function (config, data) {
  if (!store.state.alone.isLogin) return;
  if (config.custom.toast != 2) {
    app.$Message.warning(data.msg);
  }
  if (CONFIG.loginOut) {
    store.dispatch("unlogin", 0);
  }
}, 400);

function clearLoading(response) {
  if (response.config.custom.loading) {
    //避免连续的请求造成加载框闪烁
    setTimeout(() => {
      count--;
      if (count < 1) {
        try {
          //清除加载框
        } catch (e) {
          console.log(e);
        }
      }
    }, 200);
  }
}

function get(url, params = {}, custom = {}) {
  return instance({ method: "get", url, params, custom });
}
function post(url, data = {}, custom = {}) {
  return instance({ method: "post", url, data, custom });
}
function upload(url, data = {}, custom = {}) {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });
  formData.append('access_token', store.state.token);
  return instance({ method: "post", url, data: formData, custom });
}

export { get, post, upload };
