<template>
    <div>
        <Input class="form-input" :value="value" :maxlength="maxlength" :placeholder="placeholder" @input="handleInput"
            size="large" @on-focus="onFocus" @on-blur="onBlur" @on-enter="enterHandler">
        <!-- <Icon slot="append" type="ios-phone-portrait" /> -->
        <!--            <Button  icon="ios-phone-portrait"></Button>-->
        </Input>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: String
        },
        maxlength: {
            type: [String, Number],
            default: 20
        },
        placeholder: {
            type: String,
            default: '请输入'
        }
    },
    data() {
        return {
            show: false
        };
    },
    methods: {
        handleInput(e) {
            this.$emit('input', e);
        },
        onFocus() {
            this.$emit('on-focus');
        },
        onBlur() {
            this.$emit('on-blur');
        },
        enterHandler() {
            this.$emit('on-enter');
        }
    }
};
</script>

<style lang="scss" scoped></style>