export default [
  {
    name: '运营',
    path: 'home',
    component: () => import('@/views/home/index.vue'),
    meta: {
      show: true,
      sort: 0,
    },
  }
];
