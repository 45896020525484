<template>
  <!-- v-loading="routerEntering" -->
  <div id="app">
    <transition name="fade">
      <router-view />
    </transition>
  </div>
</template>

<script>

import { mapState, mapGetters } from 'vuex';
export default {
  name: 'App',
  components: {

  },
  computed: {
    ...mapState('layout', {
      routerEntering: state => state.routerEntering,
    }),
  }
};
</script>

<style lang="scss">
#app {
  font-family: "PingFang SC", "Helvetica Neue", Helvetica, "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: $background-color;
  /*font-weight: 500;*/
}
</style>
