import Vue from 'vue';

const paths = require.context('./', false, /\/.*\.js$/);

const api = {};

paths
  .keys()
  .map((item) => {
    //获取本级目录下的全部配置参数
    const key = item.replace(/(\.\/|\.js)/g, '');
    if (key == 'index' || key == 'http') return;
    api[key] = paths(item);
  });

Vue.prototype.$api = api;