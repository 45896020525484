const CONFIG = {
  // 开发环境配置
  development: {
    baseUrl: 'https://zzy2.zhangzhengyun.com/',
    loginOut: true, // 请求 code 1001 时是否登出
  },
  // 生产环境配置
  production: {
    baseUrl: 'https://zzy2.zhangzhengyun.com/',
    loginOut: true,
  }
};
export default CONFIG[process.env.NODE_ENV];