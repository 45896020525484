import child from '@/views/layout/child.vue';
export default [
  {
    name: '财务管理',
    path: 'finance',
    component: child,
    meta: {
      show: true,
      sort: 30,
    },
    children: [
      {
        name: '收益明细',
        path: 'balanceDetails',
        component: () => import('@/views/finance/balanceDetails/index.vue'),
        meta: {},
      },
      {
        name: '余额和提现',
        path: 'withdrawalApplication',
        component: () => import('@/views/finance/withdrawalApplication/index.vue'),
        meta: {},
      },
    ]
  }
];
