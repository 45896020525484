import router from '@/router';

export const dealClassifyList = (list, expand = true) => {
  list.forEach(item => {
    item.title = item.name;
    item.value = item.id;
    item.expand = expand;
    if (item.children?.length > 0) {
      dealClassifyList(item.children);
    }
  });
  return list;
};

/**
 * 转换图片实际url
 * @author Gao
 * @param urls 单个图片/数组图片/富文本
 * @param type type: video/manage(为系统图片，不属于当前店铺的，不适用店铺独立存储)有值时使用attachment_url
 * @returns {*}
 */
export function media(urls, type) {
  if (!urls) {
    return '';
  }
  return urls;
}
// 返回图片的替换图
export function replaceImage(e, type) {
  let path = '';
  switch (type) {

    default:
      // 商品图片
      // path = require('@/assets/image/replace/goods.png');
      break;
  }
  // e.target.src = '';
}

export const openNewWindowPage = (url, query) => {
  console.log('url', url, query);
  let routeUrl = router.router.resolve({
    path: url,
    query: query
  });
  window.open(routeUrl.href, '_blank');
};
export const formatNumberData = (data) => {
  if (data) {
    if (parseFloat(data) >= 1000000) {
      return `${(parseFloat(data) / 10000).toFixed(2)}万`;
    } else {
      return data;
    }
  } else {
    return '0';
  }
};
let util = {
  SIGN_REGEXP: /([yMdhsm])(\1*)/g,
  DEFAULT_PATTERN: 'yyyy-MM-dd'
};

util.padding = (s, len) => {
  let lens = len - (s + '').length;
  for (let i = 0; i < lens; i++) {
    s = '0' + s;
  }
  return s;
};
export const formatDate = (date, pattern) => {
  if (!date) {
    return '';
  }
  if (typeof date != 'object') {
    return date;
  }
  pattern = pattern || util.DEFAULT_PATTERN;
  return pattern.replace(util.SIGN_REGEXP, $0 => {
    switch ($0.charAt(0)) {
      case 'y':
        return util.padding(date.getFullYear(), $0.length);
      case 'M':
        return util.padding(date.getMonth() + 1, $0.length);
      case 'd':
        return util.padding(date.getDate(), $0.length);
      case 'w':
        return date.getDay() + 1;
      case 'h':
        return util.padding(date.getHours(), $0.length);
      case 'm':
        return util.padding(date.getMinutes(), $0.length);
      case 's':
        return util.padding(date.getSeconds(), $0.length);
    }
  });
};